<template>
  <CRow>
    <CCol md="12" class="buyServiceDetailsList">
      <div class="detailBox">
        <div class="masterInfo">
          <h4>وضعیت</h4>
          <h3>{{ detailInfo.state }}</h3>
          <h4>نوع پرداخت</h4>
          <h3>{{ detailInfo.paymentType }}</h3>
          <h4>شماره تراکنش</h4>
          <h3>{{ detailInfo.transactionNumber }}</h3>
          <h4>مبلغ</h4>
          <h3>{{ formatPrice(detailInfo.amount) }}</h3>
          <h4>موجودی فعلی</h4>
          <h3>{{ detailInfo.currentInventory }}</h3>
          <h4>موجودی قبلی</h4>
          <h3>{{ detailInfo.beforInventory }}</h3>
        </div>
        <div class="detailItem base-card">
          <div
            class="ditailRow"
            v-for="(item,index) of detailInfo.buyServiceDetailFarmInfos"
            :key="index"
          >
            <h4>زمین {{ item.farmName }}</h4>
            <h3>سال {{ item.year }} / ماه {{ item.month }}</h3>
          </div>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object
    }
  },
  components: {},
  data() {
    return {
      detailInfo: this.detail
    };
  },
  computed: {},
  methods: {
    formatPrice(Number) {
      Number += "";
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      let x = Number.split(".");
      let y = x[0];
      let z = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
      return y + z;
    }
  },
  mounted() {
  },
  created() {
  }
};
</script>

<style scoped>
.detailBox {
  float: right;
  width: 100%;
  background-color: #fff;
}

.masterInfo {
  width: 100%;
  border-right: 3px solid var(--secondary);
  display: flex;
  flex-wrap: wrap;
}

.masterInfo h4 {
  font-size: 14px;
  border: 1px solid #bbb;
  padding: 0 3px;
  height: 20px;
  color: #2196f3;
  background-color: whitesmoke;
  flex-basis: 25%;
}

.masterInfo h3 {
  font-size: 13px;
  border: 1px solid #aaa;
  padding: 2px 3px;
  height: 20px;
  flex-basis: 25%;
}

.detailItem {
  float: right;
  margin-top: 3%;
  width: 75%;
  margin-right: 12%;
  padding: 10px;
}

.ditailRow {
  width: 50%;
  float: right;
}

.detailItem h4 {
  float: right;
  font-size: 15px;
  margin: 3px 7px 3px 0;
  background-color: var(--secondary);
  padding: 2px 3px;
  color: #fff;
}

.detailItem h3 {
  float: right;
  font-size: 15px;
  margin: 3px 0;
  border: 1px solid var(--secondary);
  padding: 1px 16px;
  font-weight: bold;
}

.buyServiceDetailsList {
  min-height: 400px;
  height: 400px;
  overflow-y: auto;
}

.buyServiceDetailsList::-webkit-scrollbar {
  width: 8px;
}

.buyServiceDetailsList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

.buyServiceDetailsList::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}

.buyServiceDetailsList::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
</style>
