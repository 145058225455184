<template>
    <div class="farmInfoBox vertical-g-20">
      <div class="farmInfoBoxItem">
        <h6>نام زمین:</h6>
        <h6>{{ title }}</h6>
      </div>
      <div class="farmInfoBoxItem">
        <h6>محصول (نوع محصول):</h6>
        <h6>{{ productName }} ({{ productType }})</h6>
      </div>
      <div class="farmInfoBoxItem">
        <h6>نوع کشت (تاریخ کشت):</h6>
        <h6>{{ cultivationsTypeTitle }} ({{ cultivationsDate }})</h6>
      </div>
      <div class="farmInfoBoxItem">
        <h6>استان و شهرستان:</h6>
        <h6>{{ stateName }} ({{ cityName }})</h6>
      </div>
      <div class="farmInfoBoxItem">
        <h6>بخش و روستا:</h6>
        <h6>{{ district }} ({{ village }})</h6>
      </div>
      <div class="farmInfoBoxItem">
        <h6>هکتار:</h6>
        <h6>{{ farmArea }}</h6>
      </div>
    </div>
</template>

<script>
export default {
    name: "farmDetail",
  props: {
    farmInfoItem : {
        type : Object
    }
  },
  data() {
    return {
      title: this.farmInfoItem.title,
      productType: this.farmInfoItem.productType,
      productName: this.farmInfoItem.productName,
      cultivationsTypeTitle:  this.farmInfoItem.cultivationsTypeTitle,
      cultivationsDate:  this.farmInfoItem.cultivationsDate,
      stateName: this.farmInfoItem.stateName,
      cityName:  this.farmInfoItem.cityName,
      district:  this.farmInfoItem.district,
      village: this.farmInfoItem.village,
      farmArea : this.farmInfoItem.farmArea,
    };
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>
div.form-control {
  margin-left: 1rem;
}

.farmInfoBox {
    width: 100%;
    padding: 10px;
}
.farmInfoBoxItem{
  display: flex;
  justify-content: start;
}

.farmInfoBoxItem h6{
  flex-basis: 50%;
}

#mapBox {
  width: 100%;
  min-height: 250px;
  background-color: #bbb;
}
</style>
