<template>
  <body-with-header :title="`گزارشات و تراکنش های مالی ${activeTab}`">
    <template #header>
      <CRow class="gap-10" align-horizontal="end">
        <CButton class="y-center-g-5" :color="selectedForm === 'reportsTransactionForm' ? 'secondary' : 'primary'" variant='outline' @click="setFormActive('reportsTransactionForm')">
          <font-awesome-icon icon="wallet"/>
          <span>تراکنش های کیف پول</span>
        </CButton>
        <CButton class="y-center-g-5" :color="selectedForm === 'reportsFacktorForm' ? 'secondary' : 'primary'" variant='outline' @click="setFormActive('reportsFacktorForm')" >
          <font-awesome-icon icon="receipt"/>
          <span>فاکتورهای خرید</span>
        </CButton>
        <CButton class="y-center-g-5" :color="selectedForm === 'reportsDatesForm' ? 'secondary' : 'primary'" variant='outline' @click="setFormActive('reportsDatesForm')">
          <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
          <span>تاریخ های خریداری شده</span>
        </CButton>
      </CRow>
    </template>
    <div class="main-card py-4">
      <component v-bind:is="selectedForm" />
    </div>
  </body-with-header>
</template>

<script>
import reportsTransactionForm from "./ReportsTransaction/ReportsTransactionForm.vue";
import reportsFacktorForm from "./ReportsFacktor/ReportsFacktorForm.vue";
import reportsDatesForm from "./ReportsDates/ReportsDatesForm.vue";
import MainBodySection from "../../../components/mainBodySection.vue";
import BodyWithHeader from "../../../components/bodyWithHeader.vue";
export default {
  components: {
    BodyWithHeader,
    MainBodySection,
    reportsTransactionForm,
    reportsFacktorForm,
    reportsDatesForm,
  },
  data() {
    return {
      selectedForm: null,
      activeBtnStyle: "box-shadow: -1px 3px 6px 0px #000;background-color:green",
    };
  },
  computed: {
    activeTab(){
      switch (this.selectedForm) {
        case "reportsTransactionForm":
          return " / تراکنش های کیف پول";
        case "reportsFacktorForm":
          return " / فاکتورهای خرید";
        case "reportsDatesForm":
          return " / تاریخ های خریداری شده";
        default:
          return "";
      }
    }
  },
  methods: {
    setFormActive(selectedForm) {
      this.selectedForm = selectedForm;
    }
  },
  mounted() {
    this.setFormActive('reportsTransactionForm');
  },
};
</script>

<style scoped>


</style>
