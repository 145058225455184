<template>
  <CRow>
    <CCol md="12" class="farmListAllItem vertical-g-20">
      <SummarizeFarmCard v-for="(item, index) of farmsListItem" :key="index" :item="item"/>
    </CCol>
  </CRow>
</template>

<script>
import SummarizeFarmCard from "../../../../components/summarizeFarmCard.vue";

export default {
  props: {
    farmsList: {
      type: Array
    }
  },
  components: {SummarizeFarmCard},
  data() {
    return {
      farmsListItem : this.farmsList
    };
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {}
};
</script>

<style scoped>
.main-card {
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: 0 0 3px 0 #aaa;
  position: relative;
  background-color: whitesmoke;
  overflow: visible !important;
}
.farmName {
  position: absolute;
  top: -12px;
  right: 2%;
  background-color: #fefefe;
  color: #484747;
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50%;
}
.farmInfo {
  display: flex;
  margin-top: 10px;
}
.farmInfo h4 {
  font-size: 15px;
  background-color: var(--secondary);
  padding: 2px 3px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  color: #fff;
  margin-right: 10px;
}
.farmInfo h3 {
  font-size: 15px;
  border: 1px solid var(--secondary);
  padding: 1px 16px;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  font-weight: bold;
}
.farmAddress {
  font-size: 13px;
  padding: 0;
  color: #646464;
  margin-right: 10px;
}
.farmListAllItem {
  overflow-y: auto;
  max-height: 50vh;
  min-height: 400px;
  padding: 20px 0;
}

</style>
