<template>
  <CRow id="increaseInventory">
    <div class="body-h-fix w-100" v-if="loading">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
    </div>
    <CCol md="12" id="increaseInventoryFarmList">
      <list-with-ads v-if="buyServiceList.length > 0" :columns="{
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }" gap="40" :loading="loading">
        <factor-card v-for="(item,index) in buyServiceList" :key="index">
          <div class="d-flex justify-content-between TransationItemValue">
            <div class="y-center-g-5">
              <i class="payment-state-icon"
                 :class="item.paymentState === 'پرداخت موفق' ? 'fa fa-check text-success' : 'fa fa-close text-danger'"
                 aria-hidden="true"></i>
              <h6> {{ item.paymentState === 'پرداخت موفق' ? 'شماره فاکتور' : 'پیش فاکتور' }}</h6>
              <h6 class="chip cursor-pointer" onClick="this.copyToClipboard(item.number)">{{ item.number }}</h6>
            </div>

            <div class="y-center-g-10">
              <CButton
                v-c-tooltip="{
                            content: 'جزئیات',
                            placement: 'top',
                          }"
                color="primary"
                size="sm"
                @click="getBuyServiceDetail(item.id , item.number)"
              >
                <font-awesome-icon icon="eye"/>
              </CButton>
              <CButton
                v-c-tooltip="{
                            content: 'زمین ها',
                            placement: 'top',
                          }"
                color="primary"
                size="sm"
                @click="getBuyServiceFarms(item.id , item.number)"
              >
                <i class="fa fa-map" aria-hidden="true"></i>
              </CButton>
            </div>
          </div>
          <hr/>
          <div class="itemRow d-flex">
            <h6>تاریخ</h6>
            <h6 style="direction:ltr;">{{ item.createDate }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>تعداد ماه</h6>
            <h6>{{ item.monthCount }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>تعداد زمین</h6>
            <h6>{{ item.farmCount }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>هکتار</h6>
            <h6>{{ item.area }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>هزینه خدمات</h6>
            <h6>{{ item.costService }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>وضعیت پرداخت</h6>
            <h6>{{ item.paymentState }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>نوع پرداخت</h6>
            <h6>{{ item.paymentType }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>نوع فاکتور</h6>
            <h6>{{ item.facktorType }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>تخفیف</h6>
            <h6>{{ item.offerType }}</h6>
          </div>
          <div class="itemRow d-flex">
            <h6>توضیحات</h6>
            <h6 class="bank-description">{{ item.description }}</h6>
          </div>
          <hr/>
          <div class="itemRow d-flex">
            <h6>مبلغ</h6>
            <h6>{{ formatPrice(item.amount) }}</h6>
          </div>
        </factor-card>
      </list-with-ads>
      <h4 v-else-if="!loading" style="text-align:center;margin-top:10%;color:darkgrey">هیچ تراکنشی ثبت نشده است.</h4>
    </CCol>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      style="width: 800px !important;max-width: 800px !important"
    >
      <component
        v-if="subForm != null"
        v-bind:is="subForm"
        :farmsList="buyServiceFarmList"
        :detail="buyServiceDetail"
      />
    </VueModal>
  </CRow>
</template>

<script>
import {mapActions} from "vuex";
import farmsInfo from "./farmsInfo.vue";
import buyServiceDetail from "./buyServiceDetail.vue";
import FactorCard from "../../../../components/factorCard.vue";
import ListWithAds from "../../../../components/list-with-ads.vue";

export default {
  components: {
    ListWithAds,
    FactorCard,
    farmsInfo,
    buyServiceDetail
  },
  data() {
    return {
      increaseInventoryBox: `height: ${window.innerHeight -
      420}px;min-height: ${window.innerHeight -
      420}px;max-height: ${window.innerHeight - 420}`,
      loading: false,
      loadingText: "",
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      buyServiceList: [],
      buyServiceFarmList: [],
      buyServiceDetail: null
    };
  },
  computed: {},
  methods: {
    ...mapActions("financialUser", [
      "ReportGetAllBuyServiceWithUserId",
      "ReportGetBuyServiceDetail",
      "ReportBuyServiceGetFarmInfo"
    ]),
    ...mapActions("general", ["copyText"]),
    async copyToClipboard(value) {
      await this.copyText(value);
      this.$notify({
        title: "کپی شد",
        text: "",
        type: "success",
      });
    },
    async getAllTransaction() {
      this.loading = true;
      this.loadingText = "دریافت لیست کامل فاکتورهای شما...";
      let result = await this.ReportGetAllBuyServiceWithUserId();
      this.loading = false;
      if (result) {
        if (result.succeeded) {
          this.buyServiceList = result.data;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error"
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا رخ داده است <br /><br />`,
          type: "error"
        });
      }
    },
    async getBuyServiceFarms(buyServiceId, number) {
      this.loading = true;
      this.loadingText = "دریافت لیست زمین های فاکتور...";
      let result = await this.ReportBuyServiceGetFarmInfo({buyServiceId: buyServiceId});
      this.loading = false;
      if (result) {
        if (result.succeeded) {
          this.buyServiceFarmList = result.data;
          this.subForm = "farmsInfo";
          this.modelShowState = true;
          this.modalTitle = `لیست زمین های فاکتور ${number}`;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error"
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا رخ داده است <br /><br />`,
          type: "error"
        });
      }
    },
    async getBuyServiceDetail(buyServiceId, number) {
      this.loading = true;
      this.loadingText = "دریافت لیست جزئیات فاکتور...";
      let result = await this.ReportGetBuyServiceDetail({buyServiceId: buyServiceId});
      this.loading = false;
      if (result) {
        if (result.succeeded) {
          this.buyServiceDetail = result.data;
          this.subForm = "buyServiceDetail";
          this.modelShowState = true;
          this.modalTitle = `لیست جزئیات فاکتور ${number}`;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error"
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا رخ داده است <br /><br />`,
          type: "error"
        });
      }
    },
    formatPrice(Number) {
      Number += "";
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      let x = Number.split(".");
      let y = x[0];
      let z = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
      return y + z;
    }
  },
  mounted() {
    this.getAllTransaction();
  },
  created() {
  }
};
</script>

<style>
#increaseInventory .factor {
  height: 100%;
}
</style>
<style scoped>


.payment-state-icon {
  font-size: 20px;
}


.itemRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.itemRow .bank-description {
  max-width: 13rem;
  text-align: left;
}

.TransationItemValue h6 {
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  font-weight: bolder;
}
</style>
