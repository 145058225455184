<template>
  <CRow id="increaseInventory">
    <div class="body-h-fix w-100" v-if="loading">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
    </div>
    <CCol md="12" id="increaseInventoryFarmList">
      <list-with-ads v-if="transactionList.length > 0" :columns="{
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }" class="TransactionListItem gap-40" :loading="loading">
        <factor-card v-for="(item,index) in transactionList" :key="index">
          <div class="d-flex justify-content-between TransationItemValue">
            <div class="y-center-g-5">
              <h6>کد رهگیری</h6>
              <h6 class="chip cursor-pointer" onClick="this.copyToClipboard(item.trackingCode)">{{item.trackingCode}}</h6>
            </div>
            <div class="flex-center TransactionType" :class="item.type == 0 ? 'text-success' : 'text-danger'">
              <i :class="item.type == 0 ? 'fa fa-check' : 'fa fa-close'" aria-hidden="true"></i>
            </div>
          </div>
          <hr/>
          <div class="d-flex itemRow">
            <h6>تاریخ</h6>
            <h6 style="direction:ltr;">{{item.dateTime}}</h6>
          </div>
          <div class="d-flex itemRow">
            <h6>موجودی فعلی</h6>
            <h6>{{item.currentInventory}} ریال</h6>
          </div>
          <div class="d-flex itemRow">
            <h6>موجودی قبلی</h6>
            <h6>{{item.previousInventory}} ریال</h6>
          </div>
          <div class="d-flex itemRow">
            <h6>تخفیف</h6>
            <h6>{{item.offerCode}}</h6>
          </div>
          <div class="d-flex itemRow">
            <h6>توضیحات</h6>
            <h6>{{item.description}}</h6>
          </div>
          <div class="d-flex itemRow">
            <h6>اطلاعات بانکی</h6>
            <h6 class="bank-description">{{item.banckDescription}}</h6>
          </div>
          <hr/>
          <div class="d-flex itemRow">
            <h6>مبلغ</h6>
            <h6>{{formatPrice(item.amount)}} ریال</h6>
          </div>
        </factor-card>
      </list-with-ads>
      <h4 v-else-if="!loading" style="text-align:center;margin-top:10%;color:darkgrey">هیچ تراکنشی ثبت نشده است.</h4>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from "vuex";
import FactorCard from "../../../../components/factorCard.vue";
import ListWithAds from "../../../../components/list-with-ads.vue";

export default {
  name: "AccountTransactions",
  components: {ListWithAds, FactorCard},
  data() {
    return {
      increaseInventoryBox: `height: ${window.innerHeight -
        420}px;min-height: ${window.innerHeight -
        420}px;max-height: ${window.innerHeight - 420}`,
      loading: false,
      loadingText: "",
      transactionList: []
    };
  },
  computed: {},
  methods: {
    ...mapActions("financialUser", ["ReportGetAllTransaction"]),
    ...mapActions("general", ["copyText"]),
    async copyToClipboard(value) {
      await this.copyText({text:value});
      this.$notify({
        title: "کپی شد",
        text: "",
        type: "success",
      });
    },
    async getAllTransaction() {
      this.loading = true;
      this.loadingText = "دریافت لیست تراکنش های کیف پول شما...";
      let result = await this.ReportGetAllTransaction({});
      this.loading = false;
      if (result) {
        if (result.succeeded) {
          this.transactionList = result.data.sort((a, b) => {
              return new Date(b.dateTime) - new Date(a.dateTime);
            });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error"
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا رخ داده است <br /><br />`,
          type: "error"
        });
      }
    },
    formatPrice(Number) {
      Number += "";
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      let x = Number.split(".");
      let y = x[0];
      let z = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
      return y + z;
    }
  },
  mounted() {
    this.getAllTransaction();
  },
  created() {}
};
</script>

<style>
#increaseInventory .factor{
  height: 100%;
}
</style>
<style scoped>

.TransactionType {
  font-size: 20px;
  padding-left: 10px;
}

.itemRow{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.itemRow .bank-description{
  max-width: 17rem;
  text-align: left;
}
.TransationItemValue h6 {
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  font-weight: bolder;
}


</style>
